.modal {
  transition: 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  visibility: hidden;
  opacity: 0;
}
