.header {
  color: #f2f2f3;
  background-color: #00a4e5;
  padding: 12px 40px;
  position: relative;
  min-height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  width: 100%;
}

.navList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.navItemFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.navLink {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: #e7e7e7;
  padding: 8px;
  transition: 0.3s;
}

.navLinkActive {
  color: #f2f2f3;
}

.lang {
  color: #e7e7e7;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 8px;
}

.lang:first-of-type {
  border-right: 1px solid #d8dce5;
}

@media (hover: hover) and (pointer: fine) {
  .navLink:hover {
    color: #fff;
  }
  .lang:hover {
    color: #fff;
  }
}

@media screen and (max-width: 750px) {
  .header {
    padding: 12px;
    min-height: 48px;
  }
  .headerOpened {
    z-index: 20;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navList {
    display: none;
  }
  .text {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .navListOpen {
    height: calc(100vh - 48px);
    width: 100%;
    background-color: #00a4e5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 48px;
    right: 0;
  }
  .navLink {
    margin-bottom: 20px;
  }
  .menuIcon {
    width: 24px;
    height: 24px;
    background-image: url(../../images/header-logo.png);
    background-size: contain;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .closeButton {
    background-image: url(../../images/modal-close-button.svg);
    background-size: contain;
    width: 24px;
    height: 24px;
  }
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iconArrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
    margin-left: 4px;
  }
  .lang {
    font-size: 12px;
  }
}
