.modalContainer {
  margin: auto;
  max-width: 480px;
  width: 100%;
  background-color: #ecf0fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 40px;
  position: relative;
}

.closeButton {
  background: url(../../images/modal-close-button.svg), transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -45px;
  right: -45px;
}

@media (hover: hover) and (pointer: fine) {
  .closeButton:hover {
    opacity: 0.7;
  }
}
