.main {
  max-width: 900px;
  margin: 0 auto;
  padding: 28px 60px;
}

.modalEnterActive {
  opacity: 1;
  visibility: visible;
}

.modalEnterDone {
  opacity: 1;
  visibility: visible;
}

.modalExit {
  opacity: 1;
  visibility: visible;
}

.modalExitActive {
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-width: 750px) {
  .main {
    padding: 20px 8px;
  }
}
