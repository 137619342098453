.section {
  display: flex;
  flex-direction: column;
}

.authSection {
  max-width: 600px;
  margin: auto;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 750px) {
  .authSection {
    max-width: 85%;
    padding-top: 40px;
  }
}
