.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 40px;
}

.fields {
  border: 0;
  padding: 0;
  margin: 0 0 40px;
}

.button {
  color: #f2f2f3;
  background: #2f71e5;
  box-shadow: 0 5px 15px rgb(14 26 57 / 20%);
  border-radius: 100px;
  padding: 20px 50px;
}

.button:disabled {
  background: #1bbeff;
  cursor: default;
  opacity: 0.5;
}

.submitError {
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  color: #ff0000;
  transition: 0.3s;
  margin-top: 12px;
  opacity: 0;
  text-align: center;
}

.submitErrorActive {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    background: #347eff;
  }
  .button:disabled:hover {
    background: #1bbeff;
  }
}
