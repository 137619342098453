.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
}

.name {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 40px;
}

.text {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0;
}

.textBold {
  font-weight: 700;
}

.avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
  vertical-align: top;
}

@media screen and (max-width: 750px) {
  .profile {
    align-items: flex-start;
    margin-bottom: 40px;
  }
  .avatar {
    width: 60px;
    height: 60px;
  }
  .text {
    font-size: 14px;
  }
}
