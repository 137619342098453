.list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: calc(100vh - 125px);
  padding-right: 8px;
}

.listSmall {
  max-height: calc(100vh - 325px);
}

@media screen and (max-width: 750px) {
  .list {
    padding-left: 8px;
  }
}
