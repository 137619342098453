@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

.page {
  width: 100%;
  height: 100%;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #ecf0fb;
  color: #1e1e1e;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
}

::-webkit-scrollbar-track:vertical {
  background: #ecf0fb;
}

::-webkit-scrollbar-thumb:vertical {
  background: #8dd5ff;
  border-radius: 12px;
  box-sizing: border-box;
  border: 3px solid #ecf0fb;
}

svg {
  transition: 0.3s;
}
