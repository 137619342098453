.card {
  background-color: #fff;
  border: 1px solid #d8dce5;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
  vertical-align: top;
}

.textContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 8px;
}

.text {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.info {
  min-width: 160px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 25px;
  grid-template-areas:
    "date date count"
    "delete reply star";
  gap: 16px 8px;
  justify-content: space-between;
  justify-items: center;
}

.date {
  font-size: 14px;
  line-height: 1.8;
  color: #96a1ba;
  grid-area: date;
  border-right: 1px solid #d8dce5;
  width: 100%;
  text-align: center;
}

.count {
  font-size: 14px;
  line-height: 1.8;
  color: #96a1ba;
  grid-area: count;
  justify-self: center;
}

.starButton {
  grid-area: star;
  width: 40px;
}

.deleteButton {
  grid-area: delete;
  justify-self: end;
}

.replyButton {
  grid-area: reply;
}

.svg {
  padding: 8px;
}

.goldStar {
  fill: #ffb225;
}

.starTip {
  font-size: 12px;
  line-height: 1;
  padding: 16px 12px;
  grid-column: 1/3;
  background: #ecf0fb;
  color: #383838;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
}

@media (hover: hover) and (pointer: fine) {
  .starButton:hover + .starTip {
    visibility: visible;
    opacity: 1;
  }
  .reply:hover {
    fill: #1a1b22;
  }
  .delete:hover {
    fill: #1a1b22;
  }
  .star:hover {
    fill: #ffb225;
  }
}

@media screen and (max-width: 750px) {
  .card {
    padding: 20px;
    display: grid;
    grid-template-areas:
      "text avatar"
      "info info";
    grid-template-columns: 1fr 40px;
    gap: 12px;
  }
  .avatar {
    grid-area: avatar;
    width: 40px;
    height: 40px;
  }
  .textContainer {
    grid-area: text;
  }
  .info {
    grid-area: info;
    display: flex;
    align-items: center;
  }
  .starButton {
    order: 1;
  }
  .date {
    text-align: start;
    border-right: 0;
  }
  .count {
    padding-bottom: 4px;
    order: 2;
  }
}
