.button {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33;
  box-sizing: border-box;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  transition: 0.4s;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.button:focus {
  outline: none;
}
