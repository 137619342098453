.form {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.textarea {
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  resize: none;
  background: #ffffff;
  border: 1px solid #d8dce5;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 12px;
  transition: 0.3s;
  caret-color: #00a4e5;
}

.textarea:focus {
  outline: none;
  border-color: #8dd5ff;
}

.textarea::placeholder {
  color: #d8dce5;
}

.counter {
  font-size: 14px;
  position: absolute;
  top: 90px;
  right: 20px;
  color: #d8dce5;
}

.button {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  background-color: #1bbeff;
  padding: 8px 40px;
  transition: 0.3s;
  box-shadow: 0 5px 15px rgb(14 26 57 / 20%);
  border-radius: 4px;
}

.button:disabled {
  cursor: default;
  opacity: 0.3;
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    opacity: 0.8;
  }
  .button:disabled:hover {
    opacity: 0.3;
  }
}

@media screen and (max-width: 750px) {
  .textarea {
    width: 100%;
  }
}
