@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./Roboto-Regular.ttf") format("truetype"),
    url("./Roboto-Regular.woff2") format("woff2"),
    url("./Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Roboto-Medium.ttf") format("truetype"),
    url("./Roboto-Medium.woff2") format("woff2"),
    url("./Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Roboto-Bold.ttf") format("truetype"),
    url("./Roboto-Bold.woff2") format("woff2"),
    url("./Roboto-Bold.woff") format("woff");
}
